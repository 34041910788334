window.understanding_glasgow ||= {}

understanding_glasgow.video =
  setup: ->
    video_links = document.querySelectorAll('.video_source, .video_link')

    return false unless video_links.length > 0

    if !document.cookie.match(/understandingglasgow_cookies_consent=true/)
      video_span = document.createElement('span')
      video_span.innerHTML = ' We use cookies on our website to track and enhance your user experience. For more information or to update your cookie settings, please refer to our <a href="/cookies">cookie policy</a>. To view this functionality please <a href="/cookies-accepted" class="accept_cookies">accept cookies</a>.'
      video_links.forEach (link) ->
        link.parentNode.after(video_span)

    else
      youtube_api = document.createElement('script')
      youtube_api.src = '//www.youtube.com/iframe_api'
      document.querySelector('body').append(youtube_api)


      vimeo_api = document.createElement('script')
      vimeo_api.src = '//player.vimeo.com/api/player.js'
      document.querySelector('body').append(vimeo_api)

      setTimeout ->
        video_links.forEach (link) ->
          if link.nodeName.match(/VIDEO/)
            # CMS video
            player = link
            player.controls = true
            link.parentNode.querySelector('.video_block_poster').addEventListener 'click', (e) ->
              e.preventDefault()
              this.style.display = 'none'
              player.play()
          else
            # Third party video
            if link.href.match(/youtube.com/) || link.href.match(/youtu.be/)
              if link.parentNode.querySelector('.video_block_poster')
                link.parentNode.querySelector('.video_block_poster').addEventListener 'click', (e) ->
                  e.preventDefault()
                  this.style.display = 'none'
                  player.playVideo()

            if link.href.match(/youtube.com/)
              player = new (YT.Player)(link,
                videoId: "#{link.href.match(/v=([\w\-]+)/)[1]}")

            if link.href.match(/youtu.be/)
              player = new (YT.Player)(link,
                videoId: "#{link.href.match(/\.be\/([\w\-]*)/)[1]}")

            if link.href.match(/vimeo.com/)
              if link.parentNode.querySelector('.video_block_poster')
                link.parentNode.querySelector('.video_block_poster').addEventListener 'click', (e) ->
                  e.preventDefault()
                  this.style.display = 'none'
                  player.play()

              vimeo_video_container = document.createElement('div')
              vimeo_video_parent = link.parentNode
              vimeo_video_parent.insertBefore(vimeo_video_container, link)
              link_ids = link.href.match(/vimeo\.com.*\/(\d+)($|\/([\w]+))/)
              if link_ids[3]
                id = "https://player.vimeo.com/video/" + link_ids[1] + "?h=" + link_ids[3]
              else
                id = link_ids[1]
              player = new Vimeo.Player(vimeo_video_container,
                id: id
              )

              link.remove()
      , 1000

