window.understanding_glasgow ||= {}

understanding_glasgow.matrix =

  setup: ->
    @setup_matrix()
    
  setup_matrix: ->
    if window.matchMedia('(min-width: 1024px)').matches
      matrice_links = document.querySelectorAll('.indicator_block_link_link')
      return unless matrice_links.length

      matrice_link_items = document.querySelectorAll('.indicator_block_link_item')
      matrice_body_items = document.querySelectorAll('.indicator_block_body_item')

      matrice_links.forEach (matrice_link, idx) ->
        matrice_link.addEventListener 'mouseover', (e) ->
          
          matrice_link_items.forEach (matrice_link_item) ->
            matrice_link_item.classList.add('inactive')
          
          matrice_body_items.forEach (matrice_body_item) ->
            matrice_body_item.classList.remove('active')
          
          matrice_link_items[idx].classList.remove('inactive')
          matrice_body_items[idx].classList.add('active')
        
        matrice_link.addEventListener 'mouseleave', (e) ->
          matrice_link_items.forEach (matrice_link_item) ->
            matrice_link_item.classList.remove('inactive')
         
          matrice_body_items.forEach (matrice_body_item) ->
            matrice_body_item.classList.remove('active')
