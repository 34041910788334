window.understanding_glasgow ||= {}

window.understanding_glasgow.cookie_notice =
  setup : ->
    return false if document.cookie.match(/understandingglasgow_cookies_consent/)

    cookieNotice = document.querySelector('.cookie_notice')
    acceptButton = document.querySelector('#accept_cookies')
    declineButton = document.querySelector('#decline_cookies')

    acceptButton.addEventListener 'click', (e) ->
      e.preventDefault()
      url = e.target.getAttribute('href')
      if url == '#'
        document.cookie = 'understandingglasgow_cookies_consent=true; path=/'
        cookieNotice.style.display = 'none'
      else
        fetch url,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        .then (response) ->
          unless response.ok
            document.cookie = 'understandingglasgow_cookies_consent=true; path=/'
          cookieNotice.style.display = 'none'
          window.location.reload()

    declineButton.addEventListener 'click', (e) ->
      e.preventDefault()
      url = e.target.getAttribute('href')
      if url == '#'
        document.cookie = 'understandingglasgow_cookies_consent=false; path=/'
        cookieNotice.style.display = 'none'
      else
        fetch url,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        .then (response) ->
          unless response.ok
            document.cookie = 'understandingglasgow_cookies_consent=false; path=/'
          cookieNotice.style.display = 'none'
