window.understanding_glasgow ||= {}

understanding_glasgow.form_submit =

  setup: ->
    selects = document.querySelectorAll('.auto_submit select')
    return unless selects.length

    selects.forEach (select) ->
      select.addEventListener 'change', (e) ->
        e.target.closest('form').submit()