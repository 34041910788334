import './vendor/gsap.min.js'
import './tictoc/preview.js'
import './tictoc/ui.js'
import './understanding_glasgow/menu.js.coffee'
import './understanding_glasgow/cookie_notice.js.coffee'
import './understanding_glasgow/profile_map.js.coffee.erb'
import './understanding_glasgow/tabs.js.coffee'
import './understanding_glasgow/form_submit.js.coffee'
import './understanding_glasgow/video.js.coffee'
import './understanding_glasgow/home.js.coffee'
import './understanding_glasgow/matrix.js.coffee'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  understanding_glasgow.menu.setup();
  understanding_glasgow.cookie_notice.setup();
  understanding_glasgow.profile_map.setup();
  understanding_glasgow.tabs.setup();
  understanding_glasgow.form_submit.setup();
  understanding_glasgow.video.setup();
  understanding_glasgow.home.setup();
  understanding_glasgow.matrix.setup();
});